/**
 * @file
 * @brief Programma elettorale dei candidati alle elezioni comunali di Carpi 2024
 * 
 */
const programma = [
    {
        titolo: "1. Città delle Culture: una visione integrata attraverso gli occhi di tutte le parti della società",
        punti: [
            "Candidare Carpi Capitale Italiana della Cultura entro fine mandato;",
            "Carpi promotrice del distretto culturale Reggio Emilia-Modena-Mantova;",
            "Avviare il “museo delle arti e dei mestieri di Carpi”;",
            "Realizzare il memoriale della stazione, un nuovo spazio all'interno dei “luoghi della memoria”;",
            "Realizzazione e lancio del “Festival internazionale della Pace della Memoria”;",
            "Riattivare la Commissione delle Pari Opportunità;",
            "Realizzare la Casa delle Donne;",
            "Azione concrete contro il gender gap;",
            "Realizzare lo Sportello e la Casa delle Culture;",
            "Rafforzando le iniziative dedicate all'inclusione tra le comunità;",
            "Rilanciare la Festa del Patrono e il Carnevale;",
            "“Apriamo i sipari”: coinvolgere strutture private come spazi dedicati alla produzione locale;",
            "Semplificare le procedure fiscali per le Associazioni e gli enti in modo da ridurre il carico amministrativo;",
            "Culture giovanili: rassegna eventi per i giovani pensate dai giovani;",
            "Aprire gli spazi pubblici e crearne di nuovi dedicati all'aggregazione e alla creatività giovanile e all'incontro intergenerazionale;",
            "Avviare un “Forum Giovani” per ripensare alla città, agli spazi e alle iniziative pensate dai giovani per i giovani.",
        ]
    },
    {
        titolo: "2. Sanità vicina ed efficiente, sostegno e servizi alla persona: salute della comunità a 360°",
        punti: [
            "Sanità di Prossimità e Rete Assistenziale: rafforzare la rete dei servizi socio-sanitari attraverso il ruolo centrale della Casa di Comunità;",
            "Rafforzare il sostegno al caregiver (persona che si prende cura di famigliari o persone fragili) e offrire strumenti di sollievo e maggiori strumenti domiciliari;",
            "Completare il nuovo Ospedale di Comunità OSCO;",
            "Sostenere la realizzazione del nuovo Hospice dell'area Nord;",
            "Ottenere almeno 10 Milioni di Euro necessari per la manutenzione del Ramazzini nell'attesa del nuovo ospedale;",
            "Pretendere l'assegnazione di un maggior numero di operatori sanitari per il distretto (medici di base, infermieri, ecc..);",
            "Sostenere progetti che promuovano l'interazione tra diverse fasce di età, creando ponti tra le generazioni traducendo questi luoghi in case del volontariato;",
            "Questo è buono, prevenire è meglio! Progetto di educazione e sensibilizzazione ai corretti stili di vita;",
            "Potenziamento del Progetto Fragili e implementazione della Teleassistenza attraverso l'uso delle nuove tecnologie tra cui dispositivi di monitoraggio, assistenti vocali e domotica;",
            "Sperimentare il “condominio solidale” secondo prassi già consolidate o nuove;",
            "Investire in una rete, anche attraverso il mondo associativo, di assistenza domiciliare e di quartiere per supportare la terza età, attraverso corsi dedicati e buone pratiche per mantenersi in movimento e praticare discipline di gruppo a favore della socialità.",
        ]
    },
    {
        titolo: "3. Scuola, formazione e occupazione: una città a misura di bambino, studente e lavoratore",
        punti: [
            "Ripensare a tempi, spazi e usi della città per renderli a misura di bambine/i e adolescenti, ridisegnando gli spazi pubblici secondo una co-progettazione con le/i giovani di queste fasce d'età;",
            "Completare il percorso Carpi Campus per rafforzare l'identità del comprensivo degli Istituti scolastici superiori;",
            "Creare un centro polivalente giovani e a servizio degli istituti superiori completando i lavori dell'ex autostazione e riaprendo la sala congressi;",
            "Contrastare la dispersione scolastica attraverso mediazione culturale con le famiglie e percorsi educativi a supporto delle scuole;",
            "Favorire la creazione di percorsi di supporto psicologico per i giovani studentesse e studenti, neolaureati o lavoratrici/lavoratori;",
            "Avviare una sperimentazione di apertura delle strutture scolastiche in orario pomeridiano per favorire spazi di lavoro extra-scolastico;",
            "Aprire un tavolo permanente formazione e lavoro, che metta in rete sistema scolastico, enti formativi e mondo delle imprese;",
            "Rilanciare un evento o festival di riflessione sulla pedagogia per tutta la comunità.;",
            "Accompagnare la crescita del nuovo polo universitario, anche con l'ampliamento dei corsi con l'inserimento di una triennale;",
            "Sviluppare e sostenere il centro di ricerca sull'economia circolare e di contrasto ai cambiamenti climatici PETERMAR;",
            "Ampliare la rete dei nidi per l'infanzia, anche attraverso accordi con strutture private, per portare al 100% il tasso di richieste per i posti.",
        ]
    },
    {
        titolo: "4. Pratica, l'eccellenza e l'accessibilità nello sport: promuovere passione e professionismo, per un benessere alla portata di tutti",
        punti: [
          "Avviare un piano di manutenzione ordinaria e straordinaria di tutti gli impianti sportivi pubblici;",
          "Accompagnare la trasformazione del mondo sportivo in risposta ai recenti decreti legislativi del 2021 con l'istituzione di uno Sport Manager;",
          "Affiancare il mondo associativo nella programmazione pluriennale nella gestione degli impianti pubblici per permettere una crescita e investimenti solidi;",
          "Sostenere iniziative e giornate dello Sport dove far conoscere le associazioni del territorio e valorizzare il capitale sociale ad esso legato;",
          "Rafforzare il progetto 'Social Sport' come sostegno ai nuclei familiari con maggiori difficoltà;",
          "Sostenere percorsi per svolgere attività per l'inclusione nello sport di categorie fragili e con disabilità;",
          "Completare la nuova grande palestra polifunzionale 600 posti estendibile a 1000 del piazzale delle piscine, utilizzabile anche per competizioni agonistiche di pallacanestro, pallavolo, pallamano, calcetto e pattinaggio;",
          "Aprire da subito un percorso di individuazione di risorse per realizzare una ulteriore grande struttura per lo Sport;",
          "Lo Sport è libertà: realizzare in aree verdi pubbliche e parchi nuovi spazi per lo sport libero anche in forma autogestita."
        ]
      },
      {
        titolo: "5. Verso una città rigenerata e sostenibile: costruire il futuro, dalla mobilità alla cura dell'ambiente",
        punti: [
          "Carpi ti curo: Potenziare l'ufficio manutenzioni, tramite l'assunzione di maggior personale operaio e tecnico, per assicurare un pronto intervento e una città più curata;",
          "Costituire la prima comunità energetica di Carpi a servizio della città, avviando anche uno sportello energia per sostenere il territorio nella transizione energetica;",
          "Realizzare un centro del riuso per favorire l'economia circolare e al contempo sostenere le realtà familiari maggiormente in difficoltà;",
          "Realizzare una rete di casette dell'acqua a servizio dei quadranti urbani;",
          "Completare e mettere a servizio della città la piattaforma Smart City attraverso l'utilizzo di sensori ambientali e foto satellitari, per individuare le principali criticità di microclima urbano ed intervenire con priorità;",
          "Completare il Piano del Verde e dare attuazione alle previsioni del nuovo Piano Urbanistico per realizzare grandi fasce boscate e rafforzare le piantumazioni urbane;",
          "Ampliare l'Oasi naturalistica 'La Francesa' ed estendere il Parco Lama;",
          "Elaborazione di un piano di recupero per l'attuale area Ramazzini, pensando ad una estensione del Parco delle Rimembranze e alla riqualificazione di parte delle strutture per servizi alla persona e residenze sociali;",
          "Superamento del passaggio a livello di Via Roosevelt. Completato il progetto avviare un percorso partecipato con la città ed individuata la migliore soluzione attivarsi per individuare le risorse necessarie;",
          "Qualificare l'uscita del casello autostradale, anche attraverso la realizzazione della rotonda di accesso;",
          "Realizzare una tangenziale ciclabile sicura e veloce per gli spostamenti in città realizzando una greenway il 'triangolo verde' ripensando gli assi B.Losi, Cattani-Lama e Ferrovia;",
          "Completare la realizzazione della Bretella e individuazione di un'area di sosta attrezzata per gli autotrasportatori;",
          "Attraversamenti sicuri nei punti di maggiore criticità con una illuminazione adeguata e semafori a chiamata;",
          "Avviare un piano di abbattimento delle barriere architettoniche partendo dai principali luoghi di interesse della città;",
          "Sostituire l'attuale sistema di bike-sharing con un servizio più moderno ed efficiente affiancando luoghi di parcheggio bici sicuri nei punti maggiormente frequentati della città;",
          "Migliorare ed aumentare la dotazione di corsie ciclabili in città realizzando ove possibile separazioni fisiche dalle corsie dedicate agli autoveicoli;",
          "Obiettivo Carpi 10': Rinnovare il Trasporto Pubblico Locale, ripensando alle linee, le aree servite e ad una frequenza di 10 minuti;",
          "Potenziare il trasporto ferroviario, guardando a una maggior copertura delle fasce orarie, migliorare gli interscambi modali, promuovere nei rapporti con Regione e Ministero una metropolitana ferroviaria Rolo-Carpi-Sassuolo;",
        ]
      },
    {
        titolo: "6. Essere sicurezza: nelle piazze, sulla strada, nella comunità, partecipata e integrata",
        punti: [
            "Pretendere, attraverso l'azione politica, il riconoscimento dell'avanzamento di livello del Commissariato di Polizia di Carpi, con l'adeguamento dell'organico, fino all'istituzione di una “cabina di regia” in capo al Sindaco per il monitoraggio continuo delle esigenze del territorio.",
            "Agenti 1 a 1000: Aumentare l'organico della Polizia Locale ad almeno 105 agenti e implementare strumenti e mezzi tecnologici per migliorare le azioni del Comando.",
            "Avviare un quarto turno giornaliero con copertura notturna almeno nel fine settimana.",
            "Rafforzare le attività degli agenti verso un presidio di prossimità, con ambiti di presenza di agenti di quartiere.",
            "Implementare politiche di inclusione ed integrazione per affrontare le nuove fragilità e solitudini presenti nella comunità.",
            "Dare piena applicazione alla legge Regionale dell'Emilia-Romagna al contrasto del gioco d'azzardo.",
            "Favorire l'interazione tra cittadini attraverso iniziative come il CDV e gli street tutor, promuovendo la condivisione di informazioni.",
            "Qualificare i nostri parchi come luogo di comunità, attraverso un piano di manutenzioni, arricchimento di attrezzature per lo sport, giochi inclusivi e chioschi gestiti attraverso patti di comunità per garantire servizi e un presidio costante.",
            "Implementazione delle dotazioni strutturali del locale sistema di Protezione Civile, in un'ottica di razionalizzazione delle risorse, con la creazione di un polo integrato al coordinamento operativo e logistico del COC.",
            "Implementare e migliorare la già fattiva collaborazione con gli enti AIPO e Consorzio Bonifica Emilia Centrale per migliorare la qualità della manutenzione da porre in essere al Fiume Secchia ed al reticolo idrografico minore."
        ]
    },
    {
        titolo: "7. Sostenere il cuore di Carpi: connettere il centro alle frazioni",
        punti: [
            "Promuovere la realizzazione di almeno due parcheggi multipiano per risolvere le problematiche legate alla sosta e facilitare l'accesso al centro storico.",
            "Accompagnare la riapertura dello storico Bar Teatro e del servizio Bar della biblioteca multimediale Loria entro il 2025.",
            "Un Castello aperto, un bene della città che non può svolgere solo funzioni museali e pochi eventi, ma che possa essere dinamico e vivo, arricchito di laboratori e iniziative culturali. Un alleato sociale, un luogo di comunità.",
            "Completare il Torrione degli Spagnoli rafforzando la rete museale e culturale del Palazzo dei Pio.",
            "Valorizzare il mercato, sostenendone una qualificazione e stabilità nella programmazione per una migliore convivenza con iniziative culturali del centro.",
            "Promuovere la creazione di almeno due nuovi spazi culturali/funzionali decentralizzati rispetto a Piazza Martiri, favorendo flussi di persone nelle vie del centro favorendone una maggior attrattività.",
            "Albo degli artisti di strada autorizzati ad animare le vie e gli eventi in tutta la città.",
            "Istituire la giunta nei quartieri, con presenza costante degli assessori e del Sindaco lungo le vie, i quartieri, i parchi per un costante dialogo e ascolto della città.",
            "Sportello di Comunità: sperimentare l'inserimento di punti diffusi nelle frazioni e nei quartieri dell'amministrazione, per offrire servizi, informazioni o ricevere segnalazioni e spunti sulla città.",
            "Introdurre incentivi mirati per promuovere l'apertura di nuove attività nei quartieri e nelle frazioni dove necessario, così da favorire il commercio di dettaglio e di prossimità garantendo servizi diffusi.",
            "Favorire la rigenerazione dei circoli e centri sociali, come centri di quartiere e di comunità, anche come spazio assistenziale."
        ]
    },
    {
        titolo: "8. Innovare, crescere, attrarre: una città giovane, europea e protagonista",
        punti: [
            "Costituire un Tavolo Permanente sull'Economia e lo Sviluppo territoriale per individuare asset di sostegno al distretto produttivo e commerciale del territorio.",
            "Attivare un Ufficio Europa finalizzato ad intercettare risorse e finanziamenti attraverso i diversi bandi/fonti dell'Unione Europea, per supportare specifici investimenti pubblici e fornire supporto per le iniziative private, creando percorsi di formazione e di monitoraggio per imprese e associazioni di categoria.",
            "Ottimizzare il marketing territoriale attraverso un centro unico di promozione per coordinare tutte le attività, offrendo una programmazione chiara e pensata con il giusto tempo di coinvolgimento di tutti gli attori del territorio.",
            "Promuovere relazioni internazionali per favorire gemellaggi con città europee, così da incentivare e maturare una maggior presenza internazionale in tutti gli ambiti, dal turismo al commercio.",
            "Ottimizzare la comunicazione verso i cittadini, attraverso un uso delle tecnologie digitali ma anche con una maggior prossimità dei servizi nel territorio.",
            "Ripensare al Carpi Fashion System come sistema integrato ad obiettivi attraverso la collaborazione del mondo imprenditoriale e delle associazioni di categoria.",
            "Patto per la Casa: avviare un programma pluriennale per il riutilizzo degli immobili in disuso e la creazione di nuovi alloggi a sostegno delle famiglie e dei lavoratori, in particolare a docenti, operatori sanitari e forze dell'ordine.",
            "Supportare la rete delle attività storiche nella promozione ed istituzione delle botteghe storiche e l'apertura di botteghe per la vendita di prodotti tipici.",
            "Il primo master in umanizzazione delle professioni in collaborazione con l'Università a partire da UNIMORE.",
            "AIMAG: obiettivo rafforzare il controllo pubblico. Carpi promotrice di un patto di sindacato con tutti i soci per garantire la necessaria collaborazione tra chi ne rappresenta la proprietà pubblica a vantaggio dei cittadini che vede la nostra multiutility protagonista nella transizione ecologica."
        ]
    }
];

export default programma;
