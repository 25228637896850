import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFileArrowDown, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import logo from './res/logo.png';
import candidati from './res/candidati/candidati';
import programma from './res/programma/programma';

function App() {
  return (
    <div className="App bg-gray-50">
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

function Header() {
  return (
    <div className="p-3 md:px-10 flex flex-col justify-center items-center gap-6 bg-[#FCD317]">
      <img src={logo} className="w-1/3" alt="logo" />
      <div className="flex justify-center gap-6 flex-col text-center">
        <p className='text-4xl font-bold'>LISTA CIVICA CARPI 2.0</p>
        <p className='text-2xl text-gray-700 font-bold'>ELEZIONI TRASPARENTI</p>
        <div className="flex  flex-col md:flex-row items-center gap-4 justify-center hover:bg-[#c4aa38] rounded-lg">
          <FontAwesomeIcon icon={ faEnvelope } size='2x' color='black'/>
          <a className="font-bold break-words" href="mailto:carpi2punto0listacivica@gmail.com"><p className='break-all'>carpi2punto0listacivica@gmail.com</p></a>
        </div>
      </div>
    </div>
  );
}

function Body() {
  return (
    <div className='p-3 md:px-10 flex flex-col gap-6'>

      <div className="flex flex-col gap-3">
        <p className='text-2xl font-bold'>CANDIDATI</p>
        <div className="flex justify-center gap-6 flex-wrap">
          {candidati.map((candidato, index) => (
            <Card key={index} {...candidato} />
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <p className='text-2xl font-bold'>PROGRAMMA</p>
        <div className="flex justify-center gap-6 flex-wrap">
          {programma.map((prog, index) => (
            <ExpansionPanel key={index} {...prog} />
          ))}
        </div>
      </div>

    </div>
  );
}

function Footer() {
  return (<></>);
}

const Card = (props) => {
  return (
    <div className="bg-white rounded-lg shadow-2xl flex flex-col items-center justify-between w-[85%] md:w-[252px] p-4">
      <div className='flex flex-col items-center w-full'>
        <img 
          className='rounded-full border-[#FCD317] border-solid border-2 w-[200px] h-[200px] object-cover' 
          src={props.foto} 
          alt={'Foto ' + props.nome} 
        />
        <div className='mt-3 w-full'>
          <h1 className='text-2xl font-bold'>{props.nome}</h1>
          <p className='italic'>{props.eta}, {props.luogo_nascita}</p>
          <p className='my-3'>{props.impiego}</p>
        </div>
      </div>

      <button onClick={() => downloadFile(props.casellario)} className='flex justify-between w-full items-center gap-3 bg-gray-200 hover:scale-[1.02] rounded-lg p-3 border-[1px] border-neutral-300 border-solid'>
        <p className='col-span-3 text-left text-xs'>Certificato del casellario giudiziario</p>
          <FontAwesomeIcon icon={ faFileArrowDown } size='2x' color='black'/>
      </button>
    </div>
  );
}

const ExpansionPanel = ({ titolo, punti }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={"rounded-lg shadow-2xl w-full p-4 hover:scale-[1.02] border-[1px] border-neutral-300 border-solid"} onClick={togglePanel}>
      <button
        className="w-full rounded-lg text-left flex p-2 justify-between items-center gap-4 focus:outline-none"
      >
        <span className="text-lg font-medium">{titolo}</span>
        <span className="text-xl">{isOpen ? 
          <FontAwesomeIcon icon={ faArrowUp } color='black'/>
          : <FontAwesomeIcon icon={ faArrowDown } color='black'/>
        }</span>
      </button>
      {isOpen && (
        <div className="pb-4 px-4 md:px-8 text-justify">
          {punti.map((item, index) => (
            <p  key={index} className="py-1">
              • &ensp;{item}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

const downloadFile = (casellario) => {
  if (casellario === "todo") return alert("Casellario non disponibile");
  const url = "./res/candidati/casellari/"+casellario+".pdf";
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', casellario+'.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  );
}

export default App;
