import bellelli from './foto/bellelli.png';
import capone from './foto/capone.png'; 
import stasio from './foto/stasio.png';
import fontanesi from './foto/fontanesi.png';
import macri from './foto/macri.png';
import pardo from './foto/pardo.png';
import zini from './foto/zini.png';
import obici from './foto/obici.png';
import guerzoni from './foto/guerzoni.png';
import argenton from './foto/argenton.png';
import barbaria from './foto/barbaria.png';
import bizzozero from './foto/bizzozero.png';
import daolio from './foto/daolio.png';
import fusari from './foto/fusari.png';
import gasparini from './foto/gasparini.png';
import melegari from './foto/melegari.png';
import pavesie from './foto/pavesie.png';
import pavesis from './foto/pavesis.png';
import malavasi from './foto/malavasi.png';

/**
 * @file
 * @brief Lista dei candidati alle elezioni comunali di Carpi 2024
 * 
 */
const candidati = [
    {
        id: 1,
        foto: fontanesi,
        nome: "Carlo Alberto Fontanesi",
        eta: "77 anni",
        data_nascita: "06/08/1946",
        luogo_nascita: "Carpi (MO)",
        impiego: "Presidente Consiglio comunale, Presidente USHAC.",
        casellario: "fontanesi"
    },
    {
        id: 2,
        foto: obici,
        nome: "Chiara Obici",
        eta: "48 anni",
        data_nascita: "24/02/1976",
        luogo_nascita: "Carpi (MO)",
        impiego: "Consigliera comunale uscente, brand manager presso azienda farmaceutica.",
        casellario: "obici"
    },
    {
        id: 3,
        foto: macri,
        nome: "Maria Giovanna Macrì",
        eta: "67 anni",
        data_nascita: "20/08/1956",
        luogo_nascita: "Cursi (LE)",
        impiego: "Consigliera comunale uscente, già assistente di direzione  del cav. Renato Crotti.",
        casellario: "macri"
    },
    {
        id: 4,
        foto: guerzoni,
        nome: "Gianni Guerzoni",
        eta: "62 anni",
        data_nascita: "14/12/1961",
        luogo_nascita: "Carpi (MO)",
        impiego: "Maestro del Lavoro, consulente Qualità, volontario Protezione Civile e associazionismo culturale.",
        casellario: "guerzoni"
    },    
    {
        id: 5,
        foto: capone,
        nome: "Sergio Capone",
        eta: "65 anni",
        data_nascita: "30/08/1958",
        luogo_nascita: "Benevento",
        impiego: "Iscritto all'Albo dei Periti Industriali di Modena. Già docente del dipartimento di Informatica all'ITIS Leonardo Da Vinci di Carpi. “Tecnico commerciale esperto nel tessile abbigliamento”.",
        casellario: "capone"
    },
    {
        id: 6,
        foto: argenton,
        nome: "Giancarlo Argenton",
        eta: "55 anni",
        data_nascita: "21/12/1968",
        luogo_nascita: "Carpi (MO)",
        impiego: "Progettista elettronico e responsabile qualità prodotto in Micro System, vice presidente USHAC.",
        casellario: "argenton"
    },
    {
        id: 7,
        foto: barbaria,
        nome: "Christian Barbaria",
        eta: "42 anni",
        data_nascita: "18/01/1982",
        luogo_nascita: "Salerno",
        impiego: "Consulente assicurativo.",
        casellario: "barbaria"
    },
    {
        id: 8,
        foto: bizzozero,
        nome: "Sara Bizzozero",
        eta: "47 anni",
        data_nascita: "12/02/1977",
        luogo_nascita: "Carpi (MO)",
        impiego: "Amministratrice condominiale, property manager.",
        casellario: "bizzozero"
    },
    {
        id: 9,
        foto: daolio,
        nome: "Serena Daolio",
        eta: "51 anni",
        data_nascita: "21/06/1972",
        luogo_nascita: "Carpi (MO)",
        impiego: "Professionista culturale.",
        casellario: "daolio"
    },
    {
        id: 10,
        foto: stasio,
        nome: "Giuseppe Di Stasio (detto Pino)",
        eta: "37 anni",
        data_nascita: "04/08/1986",
        luogo_nascita: "Carpi (MO)",
        impiego: "Responsabile tecnico importante autofficina di Carpi.",
        casellario: "stasio"
    },
    {
        id: 11,
        foto: fusari,
        nome: "Pietro Fusari",
        eta: "49 anni",
        data_nascita: "23/03/1975",
        luogo_nascita: "Milano",
        impiego: "Architetto, facility manager.",
        casellario: "fusari"
    },
    {
        id: 12,
        foto: gasparini,
        nome: "Paola Gasparini",
        eta: "67 anni",
        data_nascita: "11/12/1956",
        luogo_nascita: "Carpi (MO)",
        impiego: "Già docente Liceo Manfredo Fanti di Carpi.",
        casellario: "gasparini"
    },
    {
        id: 13,
        foto: malavasi,
        nome: "Ercole Malavasi",
        eta: "63 anni",
        data_nascita: "09/06/1960",
        luogo_nascita: "Carpi (MO)",
        impiego: "Agente di commercio nel settore pubblicità per giornali e riviste.",
        casellario: "malavasi"
    },
    {
        id: 14,
        foto: melegari,
        nome: "Massimo Melegari",
        eta: "58 anni",
        data_nascita: "18/03/1966",
        luogo_nascita: "Modena",
        impiego: "Coordinatore di Recuperandia progetto dell'associazione Porta Aperta Onlus.",
        casellario: "melegari"
    },
    {
        id: 15,
        foto: pardo,
        nome: "Annunziata Pardo (detta Nancy)",
        eta: "56 anni",
        data_nascita: "21/03/1968",
        luogo_nascita: "Napoli",
        impiego: "Direttrice amministrativa azienda di robotica e ingegneria. Naturopata.",
        casellario: "pardo"
    },
    {
        id: 16,
        foto: pavesie,
        nome: "Enrico Pavesi",
        eta: "46 anni",
        data_nascita: "10/07/1977",
        luogo_nascita: "Carpi (MO)",
        impiego: "Direttore marketing Alete Bikes.",
        casellario: "pavesie"
    },
    {
        id: 17,
        foto: pavesis,
        nome: "Simonetta Pavesi",
        eta: "68 anni",
        data_nascita: "23/06/1955",
        luogo_nascita: "Milano",
        impiego: "Già docente Liceo Manfredo Fanti di Carpi.",
        casellario: "pavesis"
    },
    {
        id: 18,
        foto: zini,
        nome: "Sara Zini",
        eta: "51 anni",
        data_nascita: "08/12/1972",
        luogo_nascita: "Correggio (RE)",
        impiego: "Manager settore moda, mamma esperta in disabilità.",
        casellario: "zini"
    },
    { 
        id: 19,
        foto: bellelli, 
        nome: "Rossano Bellelli", 
        eta: "83 anni", 
        data_nascita: "16/04/1941", 
        luogo_nascita: "Carpi (MO)", 
        impiego: "Giornalista, già consigliere comunale, provinciale e regionale.", 
        casellario: "bellelli"
    },
];

export default candidati;